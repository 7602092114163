import firebase from "firebase";

export default {
    namespaced: true,

    state: {
        //Bude samostatna subkolekcia v dokumente accommodation
        qrflyerdata: null,
        initialized: false,
    },

    mutations: {
        //TODO: Toto prepíše language ak tu bude
        setQrflyerdata(state, payload) {
            state.qrflyerdata = payload;
        },
        updateQrflyerdata(state, payload) {
            state.qrflyerdata = {
                ...state.qrflyerdata,
                ...payload
            };
        },
        setInitialized(state, payload) {
            state.initialized = payload;
        }
    },

    actions: {
        async init({commit, rootGetters}) {
            if(rootGetters["getDemo"]) {
                const qrFlyerData = {
                    accommodationId: "accommodationDemo",
                    cutoff: {
                        hour: 23,
                        minute: 0,
                    },
                    languages: {
                        english: true,
                        german: true,
                    },
                    floors: [
                        {
                            editing: false,
                            first: 1,
                            last: 9,
                            exclude: [],
                        },
                        {
                            editing: false,
                            first: 10,
                            last: 19,
                            exclude: [13,14],
                        },
                        {
                            editing: false,
                            first: 20,
                            last: 29,
                            exclude: [22, 23],
                        }
                    ]
                }

                commit('setQrflyerdata', qrFlyerData);

                return;
            }

            const db = firebase.firestore();

            await new Promise(resolve => {
                const interval = setInterval(() => {
                    if(rootGetters["user/getUser"].accommodation) {
                        clearInterval(interval);
                        resolve();
                    }
                }, 100);
            });

            const accommodationId = rootGetters["user/getUser"].accommodation;

            const doc = await db
                .collection("accommodations")
                .doc(accommodationId)
                .collection("qrflyerdata")
                .doc("qrflyerdata")
                .get()

            if (!doc.exists) return;

            commit('setQrflyerdata', {accommodationId, ...doc.data()})
            commit('setInitialized', true)
        },

        async fetchQrflyerdata({commit, rootGetters}, accommodationId) {
            const db = firebase.firestore();

            const doc = await db
                .collection("accommodations")
                .doc(accommodationId)
                .collection("qrflyerdata")
                .doc("qrflyerdata")
                .get()

            if (!doc.exists) throw new Error("Qrflyerdata does not exist");

            commit('setQrflyerdata', {accommodationId, ...doc.data()})
        },

        async update({commit, rootGetters, getters}, payload) {
            commit('updateQrflyerdata', payload)

            if(rootGetters["getDemo"]) return;

            const db = firebase.firestore();

            await db
                .collection("accommodations")
                .doc(rootGetters["user/getUser"].accommodation)
                .collection("qrflyerdata")
                .doc("qrflyerdata")
                .update(payload)

        },
    },

    getters: {
        getQrflyerdata: state => {
            return state.qrflyerdata;
        },
        getInitialized: state => {
            return state.initialized;
        }
    }
}