import firebase from "firebase/app";
import {read, utils} from 'xlsx';

import {mapGetters} from "vuex";

export default {
    data() {
        return {
            render: false,
        };
    },
    methods: {
        async getExcelFile(name_) {
            const storage = firebase.storage();
            const name = `${name_.toLowerCase().replace(/ /g, "")}.xlsx`;

            const pathReference = storage.ref(name);
            localStorage.setItem("language", name_);

            try {
                var url = await pathReference.getDownloadURL();
            } catch (e) {
                console.log(e);
            }
            try {
                const response = await fetch(url, {
                    method: "GET",
                });
                const xlsxBlob = await response.blob();

                return xlsxBlob;
            } catch (e) {
                console.log(e);
            }
        },
        parseExcelFile(file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const bstr = e.target.result;
                const wb = read(bstr, {type: "binary"});
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = utils.sheet_to_json(ws, {header: 1});

                let localization = {
                    landing: [],
                    login: [],
                    register: [],
                    menu: [],
                    dashboard: [],
                    cleaningRequests: [],
                    cutoffTime: [],
                    userAccess: [],
                    orders: [],
                    feedbackBox: [],
                    feedback: [],
                    feedbackCreate: [],
                    thankyou: [],
                    footer: [],
                    resetPassword: [],
                    paymentState: [],
                    qrScanned: [],
                    confirmCleaning: [],
                    cleaningSuccess: [],
                    activation: [],
                    roomNumbers: [],
                    languageSettings: [],
                    paymentBreakdown: [],
                    success: [],
                    error: [],
                    verifyEmail: [],
                    days: []
                };

                data.forEach((el) => {
                    if (el[0]) localization.landing.push(el[0]);
                    if (el[1]) localization.login.push(el[1]);
                    if (el[2]) localization.register.push(el[2]);
                    if (el[3]) localization.menu.push(el[3]);
                    if (el[4]) localization.dashboard.push(el[4]);
                    if (el[5]) localization.cleaningRequests.push(el[5]);
                    if (el[6]) localization.cutoffTime.push(el[6]);
                    if (el[7]) localization.userAccess.push(el[7]);
                    if (el[8]) localization.orders.push(el[8]);
                    if (el[9]) localization.feedbackBox.push(el[9]);
                    if (el[10]) localization.feedback.push(el[10]);
                    if (el[11]) localization.feedbackCreate.push(el[11]);
                    if (el[12]) localization.thankyou.push(el[12]);
                    if (el[13]) localization.footer.push(el[13]);
                    if (el[14]) localization.resetPassword.push(el[14]);
                    if (el[15]) localization.paymentState.push(el[15]);
                    if (el[16]) localization.qrScanned.push(el[16]);
                    if (el[17]) localization.confirmCleaning.push(el[17]);
                    if (el[18]) localization.cleaningSuccess.push(el[18]);
                    if (el[19]) localization.activation.push(el[19]);
                    if (el[20]) localization.roomNumbers.push(el[20]);
                    if (el[21]) localization.languageSettings.push(el[21]);
                    if (el[22]) localization.paymentBreakdown.push(el[22]);
                    if (el[23]) localization.success.push(el[23]);
                    if (el[24]) localization.error.push(el[24]);
                    if (el[25]) localization.verifyEmail.push(el[25]);
                    if(el[26]) localization.days.push(el[26]);
                });

                localStorage.setItem("localization", JSON.stringify(localization));
            };

            reader.readAsBinaryString(file);
        },
        async changeListener() {
            const db = firebase.firestore();

            const docRef = await db.collection("globalId").doc("localizationId");
            docRef.get().then(async (doc) => {
                const id = doc.data().id;

                const setNewLang = async () => {
                    localStorage.setItem("localeId", id);

                    let lang = "En";

                    // switch (navigator.language) {
                    //     case "sk-SK":
                    //         lang = "Sk";
                    //         break;
                    //     case "de-DE":
                    //         lang = "De";
                    //         break;
                    //     case "it-IT":
                    //         lang = "it";
                    //         break;
                    //     default:
                    //         lang = "En";
                    //         break;
                    // }

                    const xlsxFile = await this.getExcelFile(lang);
                    this.parseExcelFile(xlsxFile);
                };

                if (!localStorage.getItem("localeId")) {
                    await setNewLang();
                } else {
                    const localeId = localStorage.getItem("localeId");
                    if (parseInt(localeId) !== parseInt(id)) {
                        await setNewLang();
                    }
                }

                setTimeout(() => {
                    this.render = true;
                }, 50);
            });
        },
    },
    computed: {
        ...mapGetters({
            uObject: "getUser"
        })
    }
};
