import firebase from "firebase";
import {isPastTime} from "@/utils/time";

export default {
    namespaced: true,

    state: {
        cleaningRequests: [],
    },

    mutations: {
        setCleaningRequests(state, cleaningRequests) {
            state.cleaningRequests = cleaningRequests;
        }
    },

    actions: {
        async fetchCleaningRequests({commit, rootGetters}, accId = null) {
            if(rootGetters["getDemo"]) {
                const tommorow = new Date();
                tommorow.setDate(tommorow.getDate() + 1);

                const today = new Date();

                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);

                const cleaningRequests = [
                    {
                        date: tommorow.toISOString().split("T")[0],
                        floor1: [1, 2, 3, 4, 5, 6, 7, 8, 9],
                        floor2: [10, 11, 12, 15, 16, 17, 18],
                        length: 16,
                    },
                    {
                        date: today.toISOString().split("T")[0],
                        floor1: [1, 2, 3, 4, 5, 9],
                        floor2: [10, 11, 15, 16, 17, 18],
                        floor3: [20, 28],
                        length: 14,
                    },
                    {
                        date: yesterday.toISOString().split("T")[0],
                        floor1: [1, 2, 3, 4, 5, 6, 7, 8, 9],
                        floor2: [10, 11],
                        length: 11,
                    }
                ]

                commit("setCleaningRequests", cleaningRequests);

                return;
            }

            if(!accId)
                accId = rootGetters["user/getUser"].accommodation;

            const db = firebase.firestore();

            const cleaningRequests = await db
                .collection("accommodations")
                .doc(accId)
                .collection("cleaningRequests")
                .get();

            if (cleaningRequests.empty) return commit("setCleaningRequests", []);

            //doc.id je v tomto prípade date vykonanie cleaning requestu v tvare yyyy-mm-dd, preto je priradený k fieldu date a nie id
            commit("setCleaningRequests", cleaningRequests.docs.map(doc => {
                const data = doc.data();

                let length = 0;
                Object.entries(data).forEach(([key, value]) => {
                    if(key === "date") return;

                    length += value.length;
                });

                return {
                    date: doc.id,
                    ...data,
                    length
                }
            }));
        },

        async createCleaningRequest({commit, rootGetters}, {accId, roomNumber, floorNumber}) {
            const db = firebase.firestore();

            const cutoffTimeDb = rootGetters['qrflyerdata/getQrflyerdata'].cutoff;

            //Kedy bude cleaning request vykonany ?
            let offset = 1;

            //Ak už prebehol cutoff time, tak sa nastaví offset na 2, čo znamená, že cleaning request bude vykonaný o dva dni
            if(isPastTime(cutoffTimeDb.hour, cutoffTimeDb.minute)) {
                offset = 2;
            }

            const cleaningDate = new Date();
            cleaningDate.setDate(cleaningDate.getDate() + offset);

            const requestId = cleaningDate.toISOString().split("T")[0];

            const fieldPath = `floor${floorNumber}`;
            const updatePayload = {};

            updatePayload[fieldPath] = firebase.firestore.FieldValue.arrayUnion(roomNumber);

            await db.collection("accommodations")
                .doc(accId)
                .collection("cleaningRequests")
                .doc(requestId)
                .set(updatePayload, {merge: true});
        }
    },

    getters: {
        getCleaningRequests: state => state.cleaningRequests,
        getCleaningRequestsByDate: state => date => state.cleaningRequests.find(cleaningRequest => cleaningRequest.date === date.toISOString().split("T")[0]),
    }
}