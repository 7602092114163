import "@babel/polyfill";
import Vue from "vue";
import firebase from "firebase";
import BootstrapVue from "bootstrap-vue";
import ToggleButton from "vue-js-toggle-button";

import "./app.scss";
import "vue-toastification/dist/index.css";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import Toast from "vue-toastification";

import DatePicker from 'v-calendar/lib/components/date-picker.umd'

import "firebase/functions";
import "firebase/firestore";

Vue.use(BootstrapVue);
Vue.use(ToggleButton);
Vue.config.productionTip = false;

Vue.component('date-picker', DatePicker)

const firebaseConfig = {
    apiKey: process.env.VUE_APP_SECRET_KEY,
    authDomain: "guestino-9a86d.firebaseapp.com",
    projectId: "guestino-9a86d",
    storageBucket: "guestino-9a86d.appspot.com",
};

firebase.initializeApp(firebaseConfig);

// firebase.firestore().useEmulator('localhost', 9090);
// firebase.auth().useEmulator('http://localhost:9099/');

const options = {
    position: "top-right",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false
};

Vue.use(Toast, options);

const vm = new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");