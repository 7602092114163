import axios from "axios";
import firebase from "firebase";
import "firebase/functions";

export default {
    data() {
        return {
            axios: null,
        }
    },

    created() {
        this.axios = axios.create({
            baseURL: "http://127.0.0.1:5001/guestino-9a86d/us-central1/",
            // baseURL: "https://us-central1-guestino-9a86d.cloudfunctions.net/",
        });

        // firebase.functions().useEmulator("127.0.0.1", 5001);
    },

    methods: {
        async inviteUser({accommodation, email}) {
            const lang = localStorage.getItem("language") || "en";
            return this.axios.post("inviteUser", {accommodation, email, lang: lang.toLowerCase()});
        },

        async removeAdditionalAccess({accommodation, uid, email}) {
            return this.axios.post("removeAdditionalAccess", {accommodation, uid, email});
        },

        async isCleaningOrdered({accommodationId, floor, room, date}) {
            return this.axios.post("isCleaningOrdered", {accommodationId, floor, room, date});
        },
        async cancelCleaningRequest({accommodationId, floor, room, date}) {
            return this.axios.post("cancelCleaningRequest", {accommodationId, floor, room, date});
        },

        async getMastersAccessPremiumStatus() {
            const functionRef = firebase.app().functions('us-central1').httpsCallable('getMastersAccessPremiumStatus');
            const {data} = await functionRef();

            return data;
        },

        async createPortalLink() {
            const functionRef = firebase
                .app()
                .functions('us-central1')
                .httpsCallable('ext-firestore-stripe-payments-createPortalLink');
            const {data} = await functionRef({
                returnUrl: "https://guestino.com/app/orders",
                locale: "auto",
            });

            return data.url;
        },
        async getAddressFromSession(sessionId) {
            const functionRef = firebase
                .app()
                .functions()
                .httpsCallable('getShippingAddress');
            const {data} = await functionRef({sessionId});

            return data.address;
        }
    }
}