import firebase from "firebase";

export default {
  namespaced: true,

  data() {
    return {
      subscription: null
    }
  },

  mutations: {
    setSubscription(state, subscription) {
      state.subscription = subscription;
    }
  },

  getters: {
    getSubscription(state) {
      return state.subscription;
    }
  },

  actions: {
    async fetchSubscription({rootGetters, commit, getters}) {
      const db = firebase.firestore();

      const docRef = await db
        .collection('customers')
        .doc(rootGetters["user/getUser"].uid)
        .collection('subscriptions')
        .orderBy('created')
        .limit(1)
        .get();

      const res = docRef.docs[0].data();

      commit('setSubscription', res);
      return res;
    },

    async createSubscription({rootGetters}, quantity) {
      const db = firebase.firestore();

      const docRef = await db
        .collection('customers')
        .doc(rootGetters["user/getUser"].uid)
        .collection('checkout_sessions')
        .add({
          collect_shipping_address: true,
          line_items: [
            {
              price: 'price_1PM7UzI5WFvVIaVSDqWvFhu5', // Recurring price ID for subscription
              quantity: quantity // Assuming quantity is 1 for subscription
            },
            {
              price: 'price_1PM7VAI5WFvVIaVSQBQ3AJo7', // Setup fee price ID
              quantity: quantity
            }
          ],
          mode: 'subscription',
          success_url: "https://guestino.com/app/payment-breakdown?success=true",
          cancel_url: "https://guestino.com/app/payment-breakdown?canceled=true",
        });

      return new Promise((resolve, reject) => {
        docRef.onSnapshot((snap) => {
          const data = snap.data();

          localStorage.setItem("sessionId", data.sessionId);

          if (data.error)
            reject(`An error occured: ${data.error.message}`);
          if (data.url)
            resolve(data.url);
        });
      });
    },
  }
}