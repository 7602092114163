<template>
  <div v-if="render">
    <div id="app">
      <router-view/>
    </div>
  </div>
  <div v-else>
    <div class="loader text-center">
      <b-spinner variant="secondary" type="grow"></b-spinner>
      <div class="my-4"><strong>Initializing languages...</strong></div>
    </div>
  </div>
</template>

<script>
import localization from "./mixins/localization.js";
import firebase from "firebase";
import cloudFunctionsMixin from "@/mixins/cloudFunctionsMixin";
import {mapActions} from "vuex";

export default {
  name: "App",
  data() {
    return {
      unsubscribe: () => {}
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  mixins: [cloudFunctionsMixin, localization],
  async created() {
    //Pozn.: inicializácia storu sa deje iba v callbacku tejto funkcie -> firne sa vždy, aj keď nieje user prihlásený
    firebase.auth().onAuthStateChanged(async (user) => {
      if (!user) {
        this.unsubscribe();
        this.unsubscribe = () => {};

        this.$store.commit("initialize");
        return;
      }

      const promises = [];

      await firebase.auth().currentUser.getIdToken(true);
      const customClaims = (await user.getIdTokenResult()).claims;
      console.log(customClaims)

      const userPayload = {
        email: user.email,
        uid: user.uid,
        emailVerified: user.emailVerified,
        stripeRole: customClaims.stripeRole,

        // Nejedná sa o admina daného ubytovania, ale o admina aplikácie
        admin: Boolean(customClaims.admin),
      }

      if (!customClaims.accommodation) userPayload.accommodation = user.uid;
      else {
        userPayload.accommodation = customClaims.accommodation
        userPayload.additional = true;

        userPayload.stripeRole = await this.getMastersAccessPremiumStatus();
      }

      this.$store.commit("user/setUser", userPayload);

      promises.push(this.$store.dispatch("accommodation/fetchAccommodation", userPayload.accommodation));
      promises.push(this.$store.dispatch("feedbacks/fetchFeedbacks", {accId:userPayload.accommodation, observe: true,}));
      promises.push(this.$store.dispatch("cleaning/fetchCleaningRequests", userPayload.accommodation));

      promises.push(this.$store.dispatch("qrflyerdata/fetchQrflyerdata", userPayload.accommodation));

      try {
        const results = await Promise.all(promises);

        if(userPayload.stripeRole === 'premium' && !this.$store.getters["accommodation/getAccommodation"].order) await this.createOrderMethod(userPayload.accommodation);

        this.unsubscribe = () => {
          console.log("Unsubscribing from all listeners");
          results[1]();
        };
      } catch (e) {
        console.warn(e);
        // this.$toast.error('Hoops, something went wrong. Please try again later.');
      } finally {
        this.$store.commit("initialize");
      }
    });

    await this.changeListener();
  },

  methods: {
    ...mapActions({
      createOrder: "orders/createOrder",
      updateAccommodation: "accommodation/updateAccommodation",
    }),

    async createOrderMethod(accommodationId) {
      const localization = JSON.parse(localStorage.getItem("localization"));

      try {
        const sessionId = localStorage.getItem("sessionId");

        if (!sessionId)
          throw new Error("No session id");

        const address = await this.getAddressFromSession(sessionId);

        const payload = {
          accommodation: accommodationId,
          status: "ordered",

          address
        }

        const orderId = await this.createOrder(payload);
        await this.updateAccommodation({
          payload: {order: orderId},
          id: accommodationId
        });
      } catch(e) {
        console.error(e)
        this.$toast.error(localization.error[1]);
      }

      await this.$router.push({name: "Dashboard"});
    },
  }
};
</script>

<style lang="scss">
.loader {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

body,
#app,
#app > div {
  min-height: 100vh;
}

#app > div {
  display: flex;
  flex-direction: column;
}

.main-cont {
  flex: 1 1;

  @media (min-width: 1200px) {
    padding: 50px 7%;
  }
}

.fake-body {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.btn {
  padding: 0.75rem 2rem;

  & span {
    font-weight: normal;
  }

  @media (min-width: 1200px) {
    padding: 0.85rem 2rem;
  }
}

.btn-secondary:hover {
  background-color: #388dab;
}

.btn-light:hover {
  background-color: #ddd;
}

.color-brand {
  color: var(--brand);
}

.color-info {
  color: var(--info);
}

.font-weight-medium {
  font-weight: 500;
}

.mobile-only {
  @media (min-width: 1200px) {
    display: none;
  }
}

.desk-only {
  display: none;

  @media (min-width: 1200px) {
    display: revert;
  }
}
</style>

<style lang="scss">
h1 {
  font-weight: 700;

  & span {
    font-weight: normal;
  }
}

.nav-link {
  outline: none 0 !important;
  box-shadow: none;
  border: 0 !important;
}

.legend {
  font-size: 14px;

  @media (min-width: 1200px) {
    font-size: 18px;
  }
}

.form-control-lg {
  font-size: 1rem !important;
}
</style>
