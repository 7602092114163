import firebase from "firebase";

export default {
    namespaced: true,

    state: {
        accommodation: {},
    },

    mutations: {
        setAccommodation(state, payload) {
            state.accommodation = payload;
        },
        updateAccommodation(state, payload) {
            Object.assign(state.accommodation, payload);
        },
    },

    actions: {
        async fetchAccommodation({commit, rootGetters}, id) {
            if (rootGetters.getDemo) {
                commit("setAccommodation", {
                    id: "accommodationDemo",
                    order: "ordered",
                    additionalAccess: [
                        {
                            email: "reception@yourhotel.com",
                            uid: "reception",
                        }
                    ]
                });

                return "accommodationDemo";
            }

            const db = firebase.firestore();

            const doc = await db
                .collection("accommodations")
                .doc(id)
                .get();


            const accommodation = {id: doc.id, ...doc.data()};

            commit("setAccommodation", accommodation);

            return doc.id;
        },
        async updateAccommodation({commit, getters, rootGetters}, {payload, id}) {
            if (rootGetters.getDemo) //TODO
                return;

            const accomodation = getters.getAccommodation;

            commit("updateAccommodation", payload);

            const db = firebase.firestore();

            // const res = await db
            //     .collection("accommodations")
            //     .doc(id)
            //     .collection("qrflyerdata")
            //     .doc("qrflyerdata")
            //     .get();
            //
            // if(!res.exists)
            //     throw new Error("Accommodation not found");

            try {
                await db
                    .collection("accommodations")
                    .doc(id)
                    .update(payload)
            } catch (error) {
                commit("updateAccommodation", accomodation);
                throw error;
            }
        },
    },
    getters: {
        getAccommodation(state) {
            return state.accommodation;
        },
        getFeedbackById: (state) => id => state.accommodation.Feedbacks.find(feedback => feedback.id === id)
    }
}