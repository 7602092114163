import firebase from "firebase";

export default {
    namespaced: true,

    state: {
        feedbacks: [],
        feedback: null,
    },

    mutations: {
        setFeedbacks(state, payload) {
            state.feedbacks = payload;
        },
        setFeedback(state, payload) {
            state.feedback = payload;
        },

        changeImportantFlagFeedback(state, id) {
            let feedback = null;

            if (state.feedbacks)
                feedback = state.feedbacks.find(feedback => feedback.id === id);

            if (!feedback)
                throw new Error("Feedback not found");

            feedback.important = !feedback.important;

            if (state.feedback && state.feedback.id === id)
                state.feedback.important = feedback.important;
        },
        markFeedbackAsSeen(state, id) {
            if (!id)
                state.feedback.seen = true;
            else {
                let feedback = null;

                if (state.feedbacks)
                    feedback = state.feedbacks.find(feedback => feedback.id === id);

                if (!feedback)
                    throw new Error("Feedback not found");

                feedback.seen = true;

                if (state.feedback && state.feedback.id === id)
                    state.feedback.seen = feedback.seen;
            }
        },
        switchResolveFeedback(state, id) {
            if (!id)
                state.feedback.resolved = !state.feedback.resolved;
            else {
                let feedback = null;

                if (state.feedbacks)
                    feedback = state.feedbacks.find(feedback => feedback.id === id);

                if (!feedback)
                    throw new Error("Feedback not found");

                feedback.resolved = !feedback.resolved;

                if (state.feedback && state.feedback.id === id)
                    state.feedback.resolved = feedback.resolved;
            }
        }
    },

    actions: {
        async fetchFeedbacks({commit, rootGetters, getters}, {accId, observe}) {
            if (rootGetters["getDemo"]) {
                if (getters.getFeedbacks.length > 0)
                    return getters.getFeedbacks;

                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);

                const feedbacks = [
                    {
                        id: "feedback1",
                        createdAt: {toDate: () => yesterday}, //Koli firebase - vracia Timestamp objekt, nie date
                        description: "Test feedback",
                        important: false,
                        resolved: false,
                        seen: false,
                        image: null,
                        place: "Test place",
                    },
                    {
                        id: "feedback2",
                        createdAt: {toDate: () => new Date(Date.now() - 1000 * 60 * 10)},
                        description: "Test feedback",
                        important: false,
                        resolved: false,
                        seen: false,
                        image: null,
                        place: "Test place",
                    },
                ];

                commit("setFeedbacks", feedbacks);

                return feedbacks;
            }

            if (!accId)
                accId = rootGetters["user/getUser"].accommodation;

            const db = firebase.firestore();

            // Vráť 'unsubscribe' funkciu na zrušenie sledovania, ak je to potrebné.
            if(observe)
                return db
                    .collection("accommodations")
                    .doc(accId)
                    .collection("feedbacks")
                    .onSnapshot(snapshot => {
                        const feedbacks = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
                        commit("setFeedbacks", feedbacks);
                    });

            const snapshot = await db
                .collection("accommodations")
                .doc(accId)
                .collection("feedbacks")
                .get();

            const feedbacks = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));

            commit("setFeedbacks", feedbacks);

            return feedbacks;
        },

        async fetchFeedback({commit, rootGetters}, {accId, id}) {
            const db = firebase.firestore();

            const doc = await db
                .collection("accommodations")
                .doc(accId)
                .collection("feedbacks")
                .doc(id)
                .get();

            if (!doc.exists)
                throw new Error("Feedback not found");

            const feedback = {id: doc.id, ...doc.data()};

            commit("setFeedback", feedback);

            return doc.id;
        },

        async changeImportantFlagFeedback({dispatch, commit, getters, rootGetters}, {accId, id}) {
            let feedback = null;

            if (!id)
                feedback = getters.getFeedback;
            else
                feedback = getters.getFeedbackById(id);

            commit("changeImportantFlagFeedback", feedback.id);

            if (!feedback)
                throw new Error("Feedback not found");

            if (rootGetters["getDemo"])
                return;

            const db = firebase.firestore();

            try {
                await db
                    .collection("accommodations")
                    .doc(accId)
                    .collection("feedbacks")
                    .doc(id)
                    .update({important: feedback.important})
            } catch (error) {
                commit("changeImportantFlagFeedback", id);
                throw error;
            }
        },

        async markFeedbackAsSeen({dispatch, commit, getters, rootGetters}, {accId, id}) {
            commit("markFeedbackAsSeen", id);

            let feedback = getters.getFeedback;

            if (!feedback)
                throw new Error("Feedback not found");

            if (rootGetters["getDemo"])
                return;

            const db = firebase.firestore();

            await db
                .collection("accommodations")
                .doc(accId)
                .collection("feedbacks")
                .doc(id)
                .update({seen: true})
        },

        async switchResolveFeedback({dispatch, commit, getters, rootGetters}, {accId, id}) {
            commit("switchResolveFeedback", id);

            const db = firebase.firestore();

            let feedback = getters.getFeedback;

            if (!feedback)
                throw new Error("Feedback not found");

            if (rootGetters["getDemo"])
                return;

            await db
                .collection("accommodations")
                .doc(accId)
                .collection("feedbacks")
                .doc(id)
                .update({resolved: feedback.resolved})
        }
    },

    getters: {
        getFeedbacks: state => state.feedbacks,
        getFeedback: state => state.feedback,
        getFeedbackById: state => id => state.feedbacks.find(feedback => feedback.id === id),
        newFeedbacks(state) {
            return state.feedbacks?.filter(feedback => !feedback.seen).length || 0;
        },
    }
}