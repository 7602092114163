import firebase from "firebase";

export default {
    namespaced: true,
    state() {
        return {
            user: null,
        }
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload;
        }
    },
    actions: {
        async login({commit, dispatch, rootGetters}, {email, password}) {
            if (rootGetters.getDemo) {
                const user = {
                    uid: "accommodationDemo",
                    email: "demo@user.com",
                    emailVerified: true,
                }
                commit("setUser", user);

                return;
            }

            const user = await firebase
                .auth()
                .signInWithEmailAndPassword(email, password);

            //customClaims
            const token = await user.user.getIdTokenResult(true);
            return Boolean(token.claims.admin);
        },

        async logout() {
            await firebase.auth().signOut();
        },

        async update({commit, rootGetters, getters}, payload) {
            commit("setUser", payload);

            const db = firebase.firestore();

            await db
                .collection("users")
                .doc(rootGetters.getUser.uid)
                .update(payload);
        },

        async createAccommodation({commit, rootGetters, getters}, uid) {
            const db = firebase.firestore();

            await db
                .collection("accommodations")
                .doc(uid)
                .set({
                    order: null,
                });

            return db
                .collection("accommodations")
                .doc(uid)
                .collection("qrflyerdata")
                .doc("qrflyerdata")
                .set({
                    floors: [],
                    languages: {},
                    cutoff: {
                        hour: 22,
                        minute: 0
                    },
                })
        },
        async register({commit, dispatch}, {email, password}) {
            const credential = await firebase
                .auth()
                .createUserWithEmailAndPassword(email, password)

            //Jazyk mailu
            firebase.auth().languageCode = navigator.language.split("-")[0];

            const promises = [];

            //Inicializacia qrflyerdata a accommodationu
            promises.push(dispatch("createAccommodation", credential.user.uid));

            promises.push(firebase
                .auth()
                .currentUser.sendEmailVerification({
                    url: "https://guestino.com/app/dashboard",
                    handleCodeInApp: true,
                }));

            await Promise.all(promises);
        },

        async signInWithProviderMiddleware({commit, dispatch}, uid) {
            commit("uninitialize", null, {root: true});

            //Zistime, či signovany user sa registruje a ak ano tak vytovorime accommodation
            const db = firebase.firestore();

            const accommodation = await db
                .collection("accommodations")
                .doc(uid)
                .get();

            if (accommodation.exists) return Promise.resolve();

            return dispatch("createAccommodation", uid);
        },
        async signInWithGoogle({dispatch}) {
            const credential = await firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider());

            //Zistime, či ma vytvoreny accommodation
            return dispatch("signInWithProviderMiddleware", credential.user.uid);
        },
        async signInWithFacebook({dispatch}) {
            const credential = (await firebase.auth().signInWithPopup(new firebase.auth.FacebookAuthProvider()));

            //Zistime, či ma vytvoreny accommodation
            dispatch("signInWithProviderMiddleware", credential.user.uid);
        }
    },
    getters: {
        getUser(state) {
            return state?.user || null;
        }
    }
}