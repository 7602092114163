import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store/index";
import firebase from "firebase";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Landing",
        component: () => import("@/pages/Landing.vue"),
    },
    {
        path: "/account",
        name: "Account",
        component: () => import("@/views/Account.vue"),
        children: [
            {
                path: "login",
                name: "Login",
                component: () => import("@/containers/account/Login.vue"),
            },
            {
                path: "register",
                name: "Register",
                component: () => import("@/containers/account/Register.vue"),
            },
            {
                path: "forgot-password",
                name: "ForgotPassword",
                component: () => import("@/containers/account/ResetPassword.vue"),
            }
        ],
    },
    {
        path: "/app",
        component: () => import("@/views/AppView.vue"),

        children: [
            {
                path: "dashboard",
                name: "Dashboard",
                component: () => import("@/containers/app/Dashboard.vue"),
            },
            {
                path: "orders",
                name: "Orders",
                component: () => import("@/containers/app/Orders.vue"),
            },
            {
                path: "feedbacks",
                name: "FeedbackBox",
                component: () => import("@/containers/app/FeedbackBox.vue"),
            },
            {
                path: "feedback/:id",
                name: "FeedbackDetail",
                component: () => import("@/containers/app/FeedbackDetail.vue"),
            },
            {
                path: "room-numbers",
                name: "RoomNumbers",
                component: () => import("@/containers/app/activation/RoomNumbers.vue"),
            },
            {
                path: "language-settings",
                name: "LanguageSettings",
                component: () => import("@/containers/app/activation/LanguageSettings.vue"),
            },
            {
                path: "payment-breakdown",
                name: "PaymentBreakdown",
                component: () => import("@/containers/app/activation/PaymentBreakdown.vue"),
            },
            {
                path: "contact-dev",
                name: "ContactDev",
                component: () => import("@/containers/app/ContactDev"),
            },
            {
                path: "user-access",
                name: "UserAccess",
                component: () => import("@/containers/app/UserAccess"),
            },
            {
                path: "cleaning-requests",
                name: "CleaningRequests",
                component: () => import("@/containers/app/CleaningRequests"),
            },
            {
                path: "cleaning-time-settings",
                name: "CutoffTime",
                component: () => import("@/containers/app/CutoffTime"),
            },
        ]
    },
    {
        path: "/verify-email",
        name: "VerifyEmail",
        component: () => import("@/containers/app/VerifyEmail"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/public",
        component: () => import("@/views/AppView.vue"),
        children: [
            {
                path: "accommodation/:id",
                name: "QrScanned",
                component: () => import("@/containers/public/QrScanned.vue"),
            },
            {
                path: "accommodation/:id/request-cleaning",
                name: "RequestCleaning",
                component: () => import("@/containers/public/cleaning/RequestCleaning.vue"),
            },
            {
                path: "cleaning-success",
                name: "CleaningSuccess",
                component: () => import("@/containers/public/cleaning/CleaningSuccess.vue"),
            },

            {
                path: "accommodation/:id/feedback",
                name: "FeedbackCreate",
                component: () => import("@/containers/public/feedback/FeedbackCreate.vue"),
            },
            {
                path: "accommodation/feedback-success",
                name: "FeedbackSuccess",
                component: () => import("@/containers/public/feedback/FeedbackSuccess.vue"),
            }
        ]
    },
    {
        path: "/admin",
        name: "Admin",
        component: () => import ("@/views/Admin.vue"),
        children: [
            {
                path: "login",
                name: "AdminLogin",
                component: () => import ("@/containers/admin/Login.vue")
            },
            {
                path: "dashboard",
                name: "AdminDashboard",
                component: () => import ("@/containers/admin/Dashboard.vue")
            },
            {
                path: "order/:id",
                name: "OrderDetail",
                component: () => import ("@/containers/admin/OrderDetail.vue")
            }
        ]
    },

    {path: "/notfound", name: "404", component: () => import("@/pages/NotFound.vue")},
    {path: "*", redirect: "/notfound"},
];


const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to, from, next) => {
    //TODO: Zapracovať route guards pre activationRequiredRoutes
    const activationRequiredRoutes = [
        "FeedbackDetail",
        "FeedbackBox",
        "UserAccess",
        "CleaningRequests",
        "CutoffTime",
        "Orders",
    ];

    const adminRoutes = [
        "AdminDashboard",
        "OrderDetail"
    ]

    const loginRequiredRoutes = [
        ...activationRequiredRoutes,
        ...adminRoutes,
        "Dashboard",
        "RoomNumbers",
        "LanguageSettings",
        "PaymentBreakdown",
    ]

    const additionalUserAvailableRoutes = [
        "FeedbackDetail",
        "FeedbackBox",
        "CleaningRequests",
        "Dashboard",
        "ContactDev"
    ]

    if (loginRequiredRoutes.includes(to.name)) {
        //Počkáme na inicializáciu store
        await new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, 1000);

            if (store.getters["getInitialized"])
                resolve();

            const interval = setInterval(() => {
                if (store.getters["getInitialized"]) {
                    clearInterval(interval);
                    resolve();
                }
            }, 100);
        })

        const user = store.getters["user/getUser"];

        if(to.query?.demo === "true") {
            await store.dispatch("initializeDemo");
            return next();
        }

        if(adminRoutes.includes(to.name) && !user.admin)
            return next({name: "Login"});
        else if (store.getters.getDemo)
            return next();
        else if (!user)
            return next({name: "Login"});
        else if (user?.stripeRole !== "premium" && activationRequiredRoutes.includes(to.name))
            return next({name: "Dashboard"});
        else if (user?.additional && !additionalUserAvailableRoutes.includes(to.name))
            return next({name: "Dashboard"})

        return next();
    } else next()
});

export default router;
