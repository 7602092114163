import firebase from "firebase";

export default {
    namespaced: true,
    state() {
        return {
            order: null,
            orders: [],
            initialized: false
        }
    },
    mutations: {
        setOrders(state, payload) {
            state.orders = payload;
        },
        setOrder(state, payload) {
            state.order = payload;
        },
        updateOrder(state, payload) {
            Object.assign(state.order, payload);
        },
        setInitialized(state, payload) {
            state.initialized = payload;
        }
    },
    actions: {
        async fetchOrders({commit}, status = null) {
            const db = firebase.firestore();

            let orders;

            if (status)
                orders = await db
                    .collection("orders")
                    .where("status", "==", status)
                    .orderBy("createdAt", "desc")
                    .get();
            else
                orders = await db
                    .collection("orders")
                    .orderBy("createdAt", "desc")
                    .get();

            commit("setOrders", orders.docs.map(doc => ({
                ...doc.data(), id: doc.id
            })));
            commit("setInitialized", true);
        },
        async fetchOrder({commit}, id) {
            console.log(id)

            const db = firebase.firestore();

            const order = await db
                .collection("orders")
                .doc(id)
                .get();

            commit("setOrder", {...order.data(), id: order.id});
        },

        async createOrder({commit}, payload) {
            const db = firebase.firestore();

            payload.createdAt = firebase.firestore.FieldValue.serverTimestamp();

            console.log(payload)
            const order = await db
                .collection("orders")
                .add(payload);

            payload.createdAt = {
                toDate: () => new Date()
            }

            commit("setOrder", {...payload, id: order.id});

            return order.id;
        },

        async updateOrder({commit}, {id, payload}) {
            const db = firebase.firestore();

            await db
                .collection("orders")
                .doc(id)
                .update(payload);

            commit("updateOrder", payload);
        }
    },
    getters: {
        getOrder(state) {
            return state.order;
        },
        getOrders(state) {
            return state.orders;
        },
        getInitialized(state) {
            return state.initialized;
        }
    }
}