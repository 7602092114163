import Vue from "vue";
import Vuex from "vuex";

import firebase from "firebase";

import accommodation from "@/store/modules/accommodation";
import qrflyerdata from "@/store/modules/qrflyerdata";
import user from "@/store/modules/user";
import orders from "@/store/modules/orders";
import feedbacks from "@/store/modules/feedbacks";
import cleaning from "@/store/modules/cleaning";
import stripe from "@/store/modules/stripe";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isMobile: {Mobile: false, Xs: false},
        addAcc: false,
        adminLogged: false,

        initialized: false,

        demo: false,
    },
    mutations: {
        setIsMobile(state, value) {
            state.isMobile = value;
        },
        setAddAcc(state, payload) {
            state.addAcc = payload;
        },
        setAdminLogged(state, payload) {
            state.adminLogged = payload;
        },

        initialize(state) {
            state.initialized = true;
        },
        uninitialize(state) {
            state.initialized = false;
        },

        setDemo(state, payload) {
            state.demo = payload;
        }
    },
    actions: {
        async logout() {
            await firebase.auth().signOut();
        },

        async initializeDemo({commit, dispatch}) {
            commit("setDemo", true);

            const promises = [];
            promises.push()

            promises.push(dispatch("user/login", {}));
            promises.push(dispatch("accommodation/fetchAccommodation", {}));
            promises.push(dispatch("qrflyerdata/init", {}));
            promises.push(dispatch("feedbacks/fetchFeedbacks", {}));
            promises.push(dispatch("cleaning/fetchCleaningRequests", {}));

            await Promise.all(promises);
        }
    },
    getters: {
        getIsMobile(state) {
            return state.isMobile;
        },
        getAddAcc(state) {
            return state.addAcc;
        },
        getAdminLogged(state) {
            return state.adminLogged;
        },
        getInitialized(state) {
            return state.initialized;
        },

        getDemo(state) {
            return state.demo;
        },
    },

    modules: {
        accommodation,
        qrflyerdata,
        user,
        orders,
        feedbacks,
        cleaning,
        stripe,
    }
});
