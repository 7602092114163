//Je už viac hodín než čas, ktorý je v parametri?
export function isPastTime(hours, minutes) {
  const now = new Date();
  const comparing = new Date();
  comparing.setHours(hours, minutes, 0);

  console.log("Now: ", now);
  console.log("Comparing: ", comparing);

  return now > comparing;
}